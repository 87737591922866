




















































































































































import { Component } from 'vue-property-decorator';
import { readUserProfile } from '@/store/main/getters';
import { dispatchGetUserProfile, dispatchUpdateUserProfile } from '@/store/main/actions';
import StateSelector from '@/components/modals/StateSelectorModal.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { getStatetextByvalue } from '@/constants/listOptions';
import { IUserProfileUpdate } from '@/interfaces/users';
import { ILicenseState } from '@/interfaces/licenceState';


@Component({ components: { StateSelector } })
export default class UserProfileEdit extends AppComponent {
  public valid = true;
  public firstName: string = '';
  public lastName: string = '';
  public email: string = '';
  public isPhysician: boolean = false;
  public healthGorillaId: string = '';
  public isNurse: boolean = false;
  public isSuperuser: boolean = false;
  public isReviewer: boolean = false;
  public isCreator: boolean = false;
  public npi: string = '';
  public licenseNumber: string = '';
  public setPassword = false;
  public password1: string = '';
  public password2: string = '';
  public assignedStates: any[] = [];


  public created() {
    this.initScreen();
  }

  /**
   * inits the screen
   * checks if current user data exist or request it if not
   */
  public async initScreen() {
    this.setAppLoading(true);
    await this.fetchData();
    this.setAppLoading(false);
    if (this.userProfile) {
      this.reset();
    } else {
      this.toast('Could not load user profile', true);
      this.$router.push('/');
    }
  }

  public async fetchData() {
    await dispatchGetUserProfile(this.$store);
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  get hasCreatorPrivileges() {
    return this.isCreator || this.isSuperuser;
  }

  public setStates(targetState) {
    if (!this.assignedStates) {
      this.assignedStates = [];
    }
    this.assignedStates.push({
      state: targetState.state.value,
      licenseNumber: targetState.licenseNumber,
    });
  }

  public reset() {
    if (this.userProfile) {
      this.setPassword = false;
      this.password1 = '';
      this.password2 = '';
      this.firstName = this.userProfile.fname;
      this.lastName = this.userProfile.lname;
      this.email = this.userProfile.email;
      this.isPhysician = this.userProfile.isPhysician;
      this.healthGorillaId = this.userProfile.healthGorillaId || '';
      this.isNurse = this.userProfile.isNursePracticioner;
      this.isSuperuser = this.userProfile.isSuperuser;
      this.isReviewer = this.userProfile.isReviewer;
      this.isCreator = this.userProfile.isCreator;
      if (this.isPhysician || this.isNurse) {
        this.npi = this.userProfile.npi || '';
        if (this.userProfile.licenseState) {
          this.assignedStates = [];
          for (const state of this.userProfile.licenseState) {
              this.assignedStates.push({
                state: state.licenseState,
                licenseNumber: state.licenseNumber,
              });
          }
        }
      }
    } else {
      this.$router.back();
    }
  }

  public removeState(index) {
    this.assignedStates.splice(index, 1);
  }

  public getStateName(state) {
    return getStatetextByvalue(state);
  }


  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if ((this.$refs.form as any).validate() && this.userProfile) {
      const updatedProfile: IUserProfileUpdate = {};
      this.setFieldIfChanges(updatedProfile, 'fname', this.firstName, this.userProfile.fname);
      this.setFieldIfChanges(updatedProfile, 'lname', this.lastName, this.userProfile.lname);
      this.setFieldIfChanges(updatedProfile, 'email', this.email, this.userProfile.email);

      if (this.setPassword) {
        if (this.hasValue((this.password1))) {
          if (this.password1 === this.password2) {
            updatedProfile.password = this.password1;
          } else {
            this.toast('Passwords do not match', true);
            return;
          }
        }
      }

      if (this.isPhysician || this.isNurse) {
        this.setFieldIfChanges(updatedProfile, 'npi', this.npi, this.userProfile.npi);
        if (this.listOfStates) {
          const licenseStates: ILicenseState | any = [];

          // we create the list of licenses from the array of states and licenses
          for (const state of this.assignedStates) {
            if (state && state.licenseNumber) {
              const currentLicense: ILicenseState = {
                licenseState: state.state,
                licenseNumber: state.licenseNumber,
              };
              licenseStates.push(currentLicense);
            }
          }
          this.setFieldIfChanges(updatedProfile, 'licenseState', licenseStates, this.userProfile.licenseState);
        }
      }
      if (!this.isEmpty(updatedProfile)) {
        await dispatchUpdateUserProfile(this.$store, updatedProfile);
        this.$router.push('/main/profile');
      }
    }
  }
}
